.ant-skeleton-element {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &.Load-Img {
    width: 100% !important;
    .ant-skeleton-image {
      width: 100% !important;
      height: 100% !important;
      border-radius: 8px !important;
      -moz-border-radius: 8px !important;
      -webkit-border-radius: 8px !important;
      aspect-ratio: 1 !important;
      object-fit: cover !important;
    }
  }
  .ant-skeleton-image {
    width: 100%;
    // height: 400px !important;
    height: 100%;
  }
  &.ImgHeight {
    .ant-skeleton-image {
      // width: 100%;
      height: 90vh !important;
    }
  }
  &.IMG {
    .ant-skeleton-image {
      // width: 100%;
      height: 181px !important;
    }
  }
  &.Height {
    .ant-skeleton-image {
      // width: 100%;
      height: 400px !important;
    }
  }
  //   .ant-skeleton-image::after {
  //     position: absolute;
  //     inset: 0 -150%;
  //     background: linear-gradient(
  //       90deg,
  //       rgba(190, 190, 190, 0.2) 25%,
  //       rgba(129, 129, 129, 0.24) 37%,
  //       rgba(190, 190, 190, 0.2) 63%
  //     );
  //     -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
  //     animation: ant-skeleton-loading 1.4s ease infinite;
  //     content: "";
  //   }
  .AnimationImg.Visible {
    opacity: 1;
    // animation: Hiiden 0.6s ease;
  }
  .AnimationImg {
    width: 100%;
    transition: 0.3s ease;
  }
  .AnimationImg.Hiiden {
    // position: absolute;
    opacity: 0;
    display: none;
    // width: 90%;
    // animation: Visible 0.6s ease;
  }
}
