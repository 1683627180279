.createWalletPage {
  #PaymentForm {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    @media screen and (max-width: 1000px) {
      flex-direction: column;
    }

    @media screen and (max-width: 600px) {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    .createButton {
      color: #fff;
      background: rgb(4, 17, 243);
      border-radius: 4px;
      border: 0;

      font-weight: bold;
      padding: 10px 20px;
    }
  }

  #click_banner_img {
    width: 70%;
    aspect-ratio: 1.5;
    object-fit: cover;

    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }

  form {
    margin-left: 30px;

    @media screen and (max-width: 600px) {
      margin: 0px;
      margin-top: 20px;
    }

    .innerForm {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      .field-set {
        width: 45%;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-top: 10px;

        @media screen and (max-width: 600px) {
          width: 100%;
          margin: 0px;
        }
      }
    }
  }

  .addCardButtonDiv {
    display: flex !important;
    align-items: center;
    justify-content: flex-end;
    width: 80%;
    @media screen and (max-width: 600px) {
      justify-content: space-between;
      width: 100%;
      .selectPlanText {
          white-space: pre;
          margin-right: 6px;
        }
    }
    .selectPlanText {
        padding: 10px 20px;
        margin-top: 10px;
    }
    // flex-direction: row-reverse;
  }
  .createButton {
    color: #fff;
    background: rgb(4, 17, 243);
    border-radius: 4px;
    border: 0;
    font-weight: bold;
    padding: 10px 20px;
    // margin-left: 20px;
    margin-top: 10px;
    white-space: pre;
  }
}
